export const handleRazorPayment = (amount, name) => {
  return new Promise((resolve, reject) => {
    const options = {
      key: "rzp_test_8UdE3f3AvtskPf",
      amount: amount * 100,
      currency: "INR",
      name: "Kiran Infotech",
      description: name,
      image: "https://kiraninfotech.co.in/Logo/Kiran Infotech-Logo.png",
      handler: function (response) {
        resolve(response.razorpay_payment_id);
      },
      modal: {
        ondismiss: function () {
          reject(
            new Error(
              "Payment modal was closed before completing the transaction."
            )
          );
        },
      },
      theme: {
        color: "#306AB2",
      },
    };

    var pay = new window.Razorpay(options);
    pay.open();
  });
};
