import { WhatsApp, PostAdd } from "@mui/icons-material";
import { Box, Fab, Tooltip } from "@mui/material";
import React from "react";

export default function QuickActions() {
  return (
    <Box sx={{ position: "fixed", bottom: 18, right: 18 }}>
      <Box>
        <Tooltip title="Add Enquiry" placement="top">
          <Fab color="secondary" sx={{ mb: 1 }}>
            <PostAdd />
          </Fab>
        </Tooltip>
      </Box>
      <Box>
        <Tooltip title="Connect us with WhatsApp" placement="top">
          <Fab sx={{backgroundColor:"#26d466", color:"white",":hover":{backgroundColor:"#26d466", color:"white"}}} color="#26d466">
            <WhatsApp />
          </Fab>
        </Tooltip>
      </Box>
    </Box>
  );
}
