import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import TileHeading from "../Components/TileHeading";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { YouTubeVideoDialogContext } from "../Context/YoutubeVideoContext";
import { LoaderContext } from "../Context/LoaderContext";
import { handleRazorPayment } from "../Components/Razorpay";

export default function Solutions() {
  const Loader = useContext(LoaderContext);
  const [solutions, setSolutions] = useState([]);

  const youtubevideo = useContext(YouTubeVideoDialogContext);

  useEffect(() => {
    Loader.handleShowLoader();
    const fetchSolutions = async () => {
      axios
        .get("/Solutions/Solutions.php")
        .then((response) => {
          if (response && response.data && response.data.data) {
            setSolutions(response.data.data);
          } else {
            console.error("Error: No data returned from API");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          Loader.handleCloseLoader();
        });
    };
    fetchSolutions();
  }, []);

  return (
    <Box>
      <TileHeading title="Solutions" backgroundcolor="#90C4EA" color="white" />
      <Container>
        {solutions.map((item) => (
          <Card
            sx={{ mt: 3, p: 2, boxShadow: 5, borderRadius: 0 }}
            key={item.id}
            data-aos="fade-right"
            data-aos-delay="500"
          >
            <Typography
              textAlign="center"
              gutterBottom
              variant="h4"
              sx={{ fontFamily: "roboto", textTransform: "uppercase" }}
            >
              {item.name}
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <CardMedia component="img" src={item.imageurl} />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <CardContent>
                  {item.description.map((description, index) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CropSquareIcon />
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ p: 1 }}
                      >
                        {description}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <Box
                  display="flex"
                  alignItems="end"
                  justifyContent="space-between"
                  flexGrow={1}
                >
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      ₹ <strong>{item.rate}</strong>
                    </Typography>
                    <Typography gutterBottom variant="body2">
                      + {item.gst}% GST
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Button variant="contained" sx={{ borderRadius: 0, ml: 1 }}>Buy Now</Button>
                    {item.videourl && (
                      <Button
                        variant="contained"
                        startIcon={<YouTubeIcon />}
                        onClick={() =>
                          youtubevideo.handleShowYoutubeDialog(
                            item.name,
                            item.videourl
                          )
                        }
                        sx={{ borderRadius: 0, ml: 1 }}
                      >
                        Watch Video
                      </Button>
                    )}
                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Container>
    </Box>
  );
}
