import { Box, Fab, Toolbar } from "@mui/material";
import React from "react";
import NavBar from "./Components/Navbar";
import Products from "./Pages/Products";
import Solutions from "./Pages/Solutions";
import AwsPlans from "./Pages/AwsPlans";
import Contacts from "./Pages/Contacts";
import { Element } from "react-scroll";
import Home from "./Pages/Home";
import About from "./Pages/About";
import BottomNavbar from "./Components/BottomNavbar";
import Helpcenter from "./Pages/Helpcenter";

export default function MainRoute() {
  return (
    <Box>
      <NavBar drawerWidth={270} />
      <Box component="main">
        <Toolbar />
        <Element name="section-home">
          <Home />
        </Element>
        <Element name="section-about">
          <About />
        </Element>
        <Element name="section-products">
          <Products />
        </Element>
        <Element name="section-solutions">
          <Solutions />
        </Element>
        <Element name="section-aws">
          <AwsPlans />
        </Element>
        <Element name="section-helpcenter">
          <Helpcenter />
        </Element>
        <Element name="section-contactus">
          <Contacts />
        </Element>
        <BottomNavbar />
      </Box>
    </Box>
  );
}
