import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import TileHeading from "../Components/TileHeading";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { LoaderContext } from "../Context/LoaderContext";
import { handleRazorPayment } from "../Components/Razorpay";

export default function Products() {
  const Loader = useContext(LoaderContext);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Loader.handleShowLoader();
    const fetchProducts = async () => {
      axios
      .get("/Products/Products.php")
      .then((response) => {
        if (response && response.data && response.data.data) {
          setProducts(response.data.data);
        } else {
          console.error("Error: No data returned from API");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        Loader.handleCloseLoader();
      });

    };
    fetchProducts();
  }, []);

  const handleProductPayment = async (amount, productname) => {
    try {
      const response = await handleRazorPayment(amount, productname);
      alert(response);
    } catch (error) {
      console.error("Error during payment processing:", error);
      alert("Payment failed: " + error.message);
    }
  };

  return (
    <Box sx={{ mb: 3}}>
      <TileHeading title="Products" backgroundcolor="#90C4EA" color="white" />
      <Container>
        {products.map((item) => (
          <div data-aos="fade-right" data-aos-delay="500">
            <Card sx={{ mt: 3, boxShadow: 5, borderRadius:0 }} key={item.id}>
              <Box sx={{ p: 2 }}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      gutterBottom
                      variant="h4"
                      sx={{ fontFamily: "roboto" }}
                    >
                     {item.name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={2}>
                  <Grid
                    item
                    md={6}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CardMedia component="img" src={item.imageurl}/>
                  </Grid>
                  <Grid item md={6}>
                    <CardContent>
                      {item.description.map((description) => (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <CropSquareIcon />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ p:1}}
                          >
                            {description}
                          </Typography>
                        </Box>
                      ))}

                      <Grid container columnSpacing={2} sx={{mt:3}} alignItems="end" >
                        <Grid item xs={12} md={4}>
                          <Typography gutterBottom variant="h5">
                           ₹ <strong>{item.rate}</strong>
                          </Typography>
                          <Typography gutterBottom variant="body2">
                            + {item.gst}% GST
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                        <Button variant="contained" sx={{borderRadius:0}} onClick={()=> handleProductPayment(item.rateincl, item.name)}>Buy Now</Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </div>
        ))}
      </Container>
    </Box>
  );
}
