import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TileHeading from "../Components/TileHeading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LoaderContext } from "../Context/LoaderContext";
import axios from "axios";

export default function Helpcenter() {
  const Loader = useContext(LoaderContext);
  const [helpVideos, setHelpVideos] = useState([]);

  useEffect(() => {
    Loader.handleShowLoader();
    const fetchHelpVideos = async () => {
      try {
        const response = await axios.get("/HelpCenter/Content/Contents.php");
        setHelpVideos(response.data.data);
        Loader.handleCloseLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        Loader.handleCloseLoader();
      }
    };
    fetchHelpVideos();
  }, []);

  return (
    <Box sx={{ mb: 3 }}>
      <TileHeading
        title="Help Videos"
        backgroundcolor="#90C4EA"
        color="white"
      />
      <Container sx={{ mt: 3 }}>
        {helpVideos.map((item) => (
          <Accordion key={item.category}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1" sx={{textTransform:"uppercase", fontFamily:"roboto", fontWeight:"bold"}}>{item.category}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container columnSpacing={2}>
                {item.content.map((content) => (
                  <Grid item md={4}>
                    <Card>
                      <Typography
                        align="center"
                        sx={{ backgroundColor: "#90C4EA", p: 1 }}
                      >
                        {content.name}
                      </Typography>
                      <Box
                        component="iframe"
                        src={content.videourl}
                        width="100%"
                        height="auto"
                        sx={{ border: 0 }}
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
}
