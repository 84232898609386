import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { YouTubeVideoDialogContext } from "../Context/YoutubeVideoContext";

export default function YoutubeVideoDialog() {
  const youtubevideo = useContext(YouTubeVideoDialogContext);

  return (
    <Dialog open={youtubevideo.state.show} maxWidth="md" fullWidth={true}>
      <DialogTitle sx={{ m: 0, p: 1 }} textAlign="center">
        {youtubevideo.state.name}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        onClick={youtubevideo.handleCloseYoutubeDialog}
      >
        <CloseIcon />
      </IconButton>

      <Box
        component="iframe"
        width="100%"
        height={315}
        src={youtubevideo.state.videourl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></Box>
    </Dialog>
  );
}
