import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import BestYourBusinessImage from "../Assets/TheBestYourBusinessCanGet.png";
import SalesIcon from "../Assets/sales-icon.svg";
import SupportIcon from "../Assets/support-icon.svg";
import CustomisationIcon from "../Assets/customisation-icon.svg";
import IntegrationIcon from "../Assets/integration-icon.svg";
export default function Home() {
  return (
    <Box>
      <Container sx={{ mt: 3, mb: 3 }}>
        <Grid container>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <Box>
            <Typography
                variant="h4"
                sx={{
                  color: "#FFC032",
                  fontFamily: "Helvetica Neue",
                  fontWeight: "bold",
                  mb:1
                }}
              >
                GIVES YOUR BUSINESS, THE GROWTH IT DESERVES
              </Typography>
              <Typography>
                Kiran Infotech have been associated with Tally Solutions Pvt.
                Ltd. as their Sales & Solution Partner for their flagship
                Enterprises Software Solutions from Tally eis 5.4 to its current
                edition of Tally Prime
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" justifyContent="end">
              <Box
                component="img"
                src={BestYourBusinessImage}
                sx={{ width:"360px", height:"360px" }}
              ></Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={4} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={3}>
            <Card sx={{ p: 1, borderRadius: 0, boxShadow: 4, mb: { xs: 1 } }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box component="img" src={SalesIcon} color="inherit" />
              </Box>
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h5">Sales</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ p: 1, borderRadius: 0, boxShadow: 4, mb: { xs: 1 } }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box component="img" src={SupportIcon} color="inherit" />
              </Box>
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h5">Support</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ p: 1, borderRadius: 0, boxShadow: 4, mb: { xs: 1 } }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box component="img" src={CustomisationIcon} color="inherit" />
              </Box>
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h5">Customisation</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card sx={{ p: 1, borderRadius: 0, boxShadow: 4, mb: { xs: 1 } }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box component="img" src={IntegrationIcon} color="inherit" />
              </Box>
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h5">Integration</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
