import { createContext, useState } from "react";

export const LoaderContext = createContext();

export const LoaderState = (props) => {
  const [state, setState] = useState(false);

  const handleShowLoader = () => {
    setState(true);
  };

  const handleCloseLoader = () => {
    setState(false);
  };

  return (
    <LoaderContext.Provider
      value={{ state, handleShowLoader, handleCloseLoader }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
};
