import * as React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../Assets/Logo_Horizontal.png';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Avatar,
  Button,
} from '@mui/material';
import { Link, animateScroll as scroll } from 'react-scroll';

const navItems = [
  { name: 'Home', path: '/Home', section: 'section-home' },
  { name: 'About Us', path: '/Aboutus', section: 'section-about' },
  { name: 'Products', path: '/Products', section: 'section-products' },
  { name: 'Solutions', path: '/Solutions', section: 'section-solutions' },
  { name: 'Tally on Cloud', path: '/TallyAWS', section: 'section-aws' },
  { name: 'Help Videos', path: '/Helpcenter', section: 'section-helpcenter' },
  { name: 'Contact Us', path: '/Contact', section: 'section-contactus' },
];

export default function NavBar(props) {
  const drawerWidth = props.drawerWidth;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const scrollToSection = (section) => {
    scroll.scrollTo('#' + section, {
      duration: 800,
      smooth: 'easeInOutQuart',
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#306AB2', height: '100%' }}>
      <Box display='flex' justifyContent='center' sx={{ my: 1 }}>
        <Avatar
          src={Logo}
          variant='square'
          sx={{ width: 135, backgroundColor: 'white', height: 'auto', p: 0.27 }}
        />
      </Box>
      <Divider sx={{ borderColor: 'white' }} />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center', color: 'white' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <CssBaseline />
      <AppBar component='nav' sx={{ backgroundColor: '#306AB2' }}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              backgroundColor: '#306AB2',
              borderRadius: 0,
              display: { sm: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar
            src={Logo}
            variant='square'
            sx={{
              width: 135,
              backgroundColor: 'white',
              height: 'auto',
              p: 0.27,
            }}
          />
          <Box sx={{ display: { xs: 'none', sm: 'block' }, ml: 'auto' }}>
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.section}
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
              >
                <Button
                  sx={{
                    p: 1.5,
                    fontWeight: 'bold',
                    borderRadius: 0,
                    color: 'white',
                    backgroundColor: '#306AB2',
                    ml: 1,
                    ':hover': { backgroundColor: '#90C4EA', color: 'black' },
                  }}
                  onClick={() => scrollToSection(item.section)}
                >
                  {item.name}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
