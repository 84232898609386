import {
  Container,
  Card,
  Box,
  TextField,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  Toolbar,
  Button,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../Context/LoaderContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import TimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Translate";
import OnlineIcon from "@mui/icons-material/Wifi";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import OfflineIcon from "@mui/icons-material/WifiOff";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import SimpleAppbar from "../Components/SimpleAppbar";
import { useSnackbar } from "notistack";
import { handleRazorPayment } from "../Components/Razorpay";

export default function EventRegistration() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const Loader = useContext(LoaderContext);

  const [event, setEvent] = useState(null);

  const [participant, setParticipant] = useState({
    eventid: id,
    name: "",
    company: "",
    mobile: "",
    email: "",
    seats: 1,
    rayzorpayid: "",
  });

  useEffect(() => {
    axios
      .get(`/Events/Events.php?id=${id}`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          setEvent(response.data.data);
        } else {
          console.error("Error: No data returned from API");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        Loader.handleCloseLoader();
      });
  }, [id]);

  const handleInputChange = (e) => {
    setParticipant((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (event && event.type) {
      if (event.type === "Paid") {
        handleRazorPayment(event.rateincl, event.name);
      } else {
      }
    }
  };

  return (
    <>
      <Toolbar />
      <SimpleAppbar title="Event Registration" />
      <Container sx={{ mt: 3 }}>
        {event && (
          <Card sx={{ p: 2 }}>
            <Box>
              <Grid container columnSpacing={2}>
                <Grid item md={3}>
                  <CardMedia component="img" src={event.imageurl} />
                </Grid>
                <Grid item md={5}>
                  <CardContent>
                    <Typography variant="h5">{event.name}</Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      {event.agenda}
                    </Typography>
                  </CardContent>
                </Grid>
                <Grid item md={4}>
                  <CardContent>
                    <Box display="flex" sx={{ p: 1 }}>
                      <CalendarIcon />
                      <Typography sx={{ ml: 1 }}>{event.date}</Typography>
                    </Box>
                    <Box display="flex" sx={{ p: 1 }}>
                      <TimeIcon />
                      <Typography sx={{ ml: 1 }}>
                        {event.starttime} to {event.endtime}
                      </Typography>
                    </Box>
                    <Box display="flex" sx={{ p: 1 }}>
                      <LanguageIcon />
                      <Typography sx={{ ml: 1 }}>{event.language}</Typography>
                    </Box>
                    <Box display="flex" sx={{ p: 1 }}>
                      <ModelTrainingIcon />
                      <Typography sx={{ ml: 1 }}>{event.type}</Typography>
                    </Box>

                    <Box display="flex" sx={{ p: 1 }}>
                      {event.mode === "Online" ? (
                        <OnlineIcon />
                      ) : (
                        <OfflineIcon />
                      )}
                      <Typography sx={{ ml: 1 }}>{event.mode}</Typography>
                    </Box>
                  </CardContent>
                </Grid>
              </Grid>
            </Box>
            <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                label="Name"
                fullWidth
                name="name"
                size="small"
                value={participant.name}
                required
                sx={{ mb: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Company Name"
                name="company"
                fullWidth
                size="small"
                value={participant.company}
                sx={{ mb: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Email ID"
                name="email"
                type="email"
                fullWidth
                size="small"
                value={participant.email}
                sx={{ mb: 2 }}
                onChange={handleInputChange}
              />
              <TextField
                label="Mobile"
                required
                fullWidth
                size="small"
                value={participant.mobile}
                sx={{ mb: 2 }}
                name="mobile"
                onChange={handleInputChange}
              />

              <Box
                sx={{ mb: 2 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  sx={{
                    borderRadius:0,
                    backgroundColor: "#306AB2",
                    color: "white",
                    ":hover": { backgroundColor: "#90C4EA" },
                  }}
                  disabled={parseFloat(participant.seats) <= 1}
                  onClick={() =>
                    setParticipant((prevState) => ({
                      ...prevState,
                      seats: prevState.seats - 1,
                    }))
                  }
                >
                  <MinusIcon />
                </IconButton>
                <Typography sx={{ ml: 3, mr: 3 }}>
                  {participant.seats}
                </Typography>

                <IconButton
                  sx={{
                    borderRadius:0,
                    backgroundColor: "#306AB2",
                    color: "white",
                    ":hover": { backgroundColor: "#90C4EA" },
                  }}
                  onClick={() =>
                    setParticipant((prevState) => ({
                      ...prevState,
                      seats: prevState.seats + 1,
                    }))
                  }
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Button variant="contained" type="submit" fullWidth>
                Submit
              </Button>
            </Box>
          </Card>
        )}
      </Container>
    </>
  );
}
