import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import TileHeading from "../Components/TileHeading";

export default function About() {
  return (
    <Box>
      <TileHeading title="About Us" backgroundcolor="#306AB2" color="white" />
      <Container sx={{mt:3}}>
        <Grid container columnSpacing={2} sx={{mb:3}}>
          <Grid item md={6}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>ESTABLISHED IN 1999</Typography>
            <Typography>
              Kiran Infotech established in 1999, as a Partnership Firm,
              registered under The Indian Partnership Act 1932, has been
              providing Enterprises Software Solutions with core focus on
              Accounts, Inventory & Statutory Management.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>OUR JOURNEY</Typography>
            <Typography>
              Ever since its establishment in 1999, we have been associated with
              Tally Solutions Pvt. Ltd. as their Sales & Solution Partner for
              their flagship Enterprises Software Solutions from Tally eis 5.4
              to its current edition of Tally.ERP 9. Started from 2 person
              organisation to 18 people, expanding from single city to 24
              Districts of 2 States.
            </Typography>
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} sx={{mb:3}}>
          <Grid item md={6}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>OUR INFRASTRUCTURE</Typography>
            <Typography>
              Catering to the MSME’s in the Southern Region of Maharashtra with
              office at Solapur : Maharashtra and Northern Region of Karnataka
              with office at Hubballi : Karnataka with major focus on Retail,
              Manufacturing, Services and other Verticals.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>PURPOSE OF OUR EXISTENCE</Typography>
            <Typography>
              The purpose of our existence have been the MSME’s we have been
              connecting and helping them achieve new scale via new dimensions
              and thus their next levels of desired goals… Our purpose thus acts
              as our guiding principle for everything we do right from our
              policies to their executions
            </Typography>
          </Grid>
        </Grid>

        <Grid container columnSpacing={2} sx={{mb:3}}>
          <Grid item md={6}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>TALLY DEVELOPMENT LANGUAGE</Typography>
            <Typography>
              Managed by Partners with in-depth knowledge of Indian Accounting &
              Taxation procedures coupled with Software Development Languages,
              mainly TDL (Tally Development Language) having wide experience
              ranging from 10 to 18 years.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" sx={{fontWeight:"bold"}}>OUR VISION</Typography>
            <Typography>
              Our vision of “MSME’s to be the driving force of Indian Economy”
              as we believe that “Velocity of Money” is key to driving economic
              growth of our country.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
