import { createContext, useState } from "react";

export const YouTubeVideoDialogContext = createContext();

export const YouTubeVideoDialogState = (props) => {
  const [state, setState] = useState({
    show: false,
    name: "Module",
    videourl: "",
  });

  const handleShowYoutubeDialog = (name, videourl) => {
    setState({ show: true, name: name, videourl: videourl });
  };

  const handleCloseYoutubeDialog = () => {
    setState({ show: false, name: "", videourl: "" });
  };

  return (
    <YouTubeVideoDialogContext.Provider value={{state, handleShowYoutubeDialog, handleCloseYoutubeDialog}}>
      {props.children}
    </YouTubeVideoDialogContext.Provider>
  );
};
