import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../Context/LoaderContext";
import QRCode from "qrcode.react";
import { Helmet } from "react-helmet";
import SimpleAppbar from "../Components/SimpleAppbar";
import AddressIcon from "@mui/icons-material/FmdGood";
import SupportIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export default function PartnerAwsPlanPrice() {
  const Loader = useContext(LoaderContext);

  const [awsPlans, setAwsPlans] = useState([]);
  const [branches, setBranches] = useState([]);

  const [awsPriceData, setAwsPriceData] = useState(null);

  useEffect(() => {
    const fetchAwsPlans = async () => {
      Loader.handleShowLoader();
      try {
        const response = await axios.get("/AwsPlans/AwsPlans.php");
        setAwsPlans(response.data.data);
        Loader.handleCloseLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAwsPlans();

    const fetchContacts = async () => {
      try {
        const response = await axios.get("/Contacts/Contacts.php");
        setBranches(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAwsPlans();
    fetchContacts();
  }, []);

  const handleAwsPlanChange = async (selected) => {
    if (selected) {
      const AwsPlanName = selected.name;
      Loader.handleShowLoader();
      try {
        const response = await axios.get(
          `/AwsPlans/AwsPlans.php?name=${AwsPlanName}&category=partner`
        );
        setAwsPriceData(response.data.data);
        Loader.handleCloseLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        Loader.handleCloseLoader();
      }
    }
  };

  return (
    <>
      <SimpleAppbar title="Partner Price on Tally Prime on AWS" />
      <Toolbar />
      <Container sx={{ mt: 3 }}>
        <Helmet>
          <title>Partner AWS Price | Kiran Infotech</title>
          <meta
            name="description"
            content="This "
          />
        </Helmet>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(e, newValue) => handleAwsPlanChange(newValue)}
              options={awsPlans}
              fullWidth
              autoHighlight
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <Grid container key={option.id}>
                    <Grid item xs={10}>
                      {option.name}
                    </Grid>
                    <Grid item xs={2} justifyContent="end">
                      {option.users}
                    </Grid>
                  </Grid>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose an AWS Plan"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Table sx={{ mt: 3 }}>
          <TableRow>
            <TableCell colSpan={2}>Concurrent Users</TableCell>
            <TableCell colSpan={2}>
              {awsPriceData ? awsPriceData.users : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Duration of Usage</TableCell>
            <TableCell colSpan={2}>
              {awsPriceData ? awsPriceData.duration : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>RAM</TableCell>
            <TableCell colSpan={2}>
              {awsPriceData ? awsPriceData.ram : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Storage</TableCell>
            <TableCell colSpan={2}>
              {awsPriceData ? awsPriceData.storage : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Backup Storage</TableCell>
            <TableCell colSpan={2}>
              {awsPriceData ? awsPriceData.backupstorage : null}
            </TableCell>
          </TableRow>
        </Table>

        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#306AB2" }}>
                <TableCell sx={{ color: "white" }}>Particulars</TableCell>
                <TableCell align="right" sx={{ color: "white" }}>
                  3 Months
                </TableCell>
                <TableCell align="right" sx={{ color: "white" }}>
                  6 Months
                </TableCell>
                <TableCell align="right" sx={{ color: "white" }}>
                  12 Months
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Taxable Value</TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.values.Quaterly.amount : null}
                </TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.values.HalfYearly.amount : null}
                </TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.values.Yearly.amount : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  GST {awsPriceData ? `@ ${awsPriceData.gst} %` : null}
                </TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.values.Quaterly.gst : null}
                </TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.values.HalfYearly.gst : null}
                </TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.values.Yearly.gst : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight:"bold"}}>Grand Total</TableCell>
                <TableCell align="right" sx={{fontWeight:"bold"}}>
                  {awsPriceData ? awsPriceData.values.Quaterly.total : null}
                </TableCell>
                <TableCell align="right" sx={{fontWeight:"bold"}}>
                  {awsPriceData ? awsPriceData.values.HalfYearly.total : null}
                </TableCell>
                <TableCell align="right" sx={{fontWeight:"bold"}}>
                  {awsPriceData ? awsPriceData.values.Yearly.total : null}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3}>
                  Additional Charges per Hour after Duration of Usage (Exclusive
                  of GST)
                </TableCell>
                <TableCell align="right">
                  {awsPriceData ? awsPriceData.hourlycharges : null}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {branches.map((item) => (
          <Grid
            container
            justifyContent="center"
            sx={{ mt: 3, boxShadow: 1, pl: 3, pr: 3, pt: 1, pb: 1 }}
          >
            <Grid item xs={12} md={6} sx={{ mb: { xs: 1 } }}>
              <Typography variant="h5">{item.name}</Typography>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <AddressIcon />
                </Grid>
                <Grid item>
                  {item.address.map((address) => (
                    <Typography>{address}</Typography>
                  ))}
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <PhoneIcon />
                </Grid>
                <Grid item>
                  <Typography>{item.phone}</Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <SupportIcon />
                </Grid>
                <Grid item>
                  <Typography>{item.supportno}</Typography>
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <EmailIcon />
                </Grid>
                <Grid item>
                  <Typography>{item.email}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} sx={{ mb: { xs: 1 } }}>
              <Typography gutterBottom>Bank Details</Typography>
              <Typography>Bank Name : {item.bankname}</Typography>
              <Typography>Account No : {item.accountno}</Typography>
              <Typography>IFSC Code : {item.ifsc}</Typography>
              <Typography>Branch Name : {item.branch}</Typography>
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography textAlign="center">Scan to Pay</Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <QRCode
                  value={`upi://pay?pa=${item.upiid}&pn=Kiran%20Infotech&mc=123&tid=456&tr=123&tn=Payment%20for%20purchase&cu=INR`}
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      </Container>
    </>
  );
}
