import React, { useEffect, useState } from "react";
import TileHeading from "../Components/TileHeading";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import AddressIcon from "@mui/icons-material/FmdGood";
import SupportIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

export default function Contacts() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get("/Contacts/Contacts.php");
        setContacts(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchContacts();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#306AB2", color: "white" }}>
      <TileHeading title="Contact Us" color="white" />
      <Container>
        <Grid container columnSpacing={4}>
          {contacts.map((item) => (
            <Grid item xs={12} md={6} sx={{ mb: { xs: 2, md: 0 } }}>
              <Typography variant="h5">{item.name}</Typography>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <AddressIcon />
                </Grid>
                <Grid item>
                  {item.address.map((address) => (
                    <Typography>{address}</Typography>
                  ))}
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <PhoneIcon />
                </Grid>
                <Grid item>
                  <Typography>{item.phone}</Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <SupportIcon />
                </Grid>
                <Grid item>
                  <Typography>{item.supportno}</Typography>
                </Grid>
              </Grid>

              <Grid container columnSpacing={2} alignItems="center">
                <Grid item>
                  <EmailIcon />
                </Grid>
                <Grid item>
                  <Typography>{item.email}</Typography>
                </Grid>
              </Grid>

              <Box
                component="iframe"
                src={item.mapurl}
                width="100%"
                sx={{ height: 360, border: 0 }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
