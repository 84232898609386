import { Box, Typography } from "@mui/material";
import React from "react";

export default function TileHeading(props) {
  return (
    <Box
      sx={{ backgroundColor: props.backgroundcolor, textAlign: "center", p: 1 }}
    >
      <Typography variant="h4" sx={{ color: props.color, fontFamily:"Helvetica Neue", textTransform:"uppercase" }}>
        {props.title}
      </Typography>
    </Box>
  );
}
