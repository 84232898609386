import { Backdrop } from "@mui/material";
import React, { useContext } from "react";
import { LoaderContext } from "../Context/LoaderContext";
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
  const Loader = useContext(LoaderContext)
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={Loader.state}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
