import { AppBar, Avatar, Toolbar, Box, Typography } from "@mui/material";
import React from "react";
import Logo from "../Assets/Logo_Horizontal.png";
import { Link } from "react-router-dom";

export default function SimpleAppbar(props) {
  return (
    <AppBar>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }} component={Link} to="/">
          <Avatar
            src={Logo}
            variant="square"
            sx={{
              width: 135,
              backgroundColor: "white",
              height: "auto",
              p: 0.27,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{fontFamily:"Helvetica Neue", textTransform:"uppercase", ml:{xs:1, sm:0}}}>
            {props.title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: {xs:"none", sm:"flex"},
            alignItems: "center",
            width: 135,
            backgroundColor: "white",
            height: "auto",
          }}
        ></Box>
      </Toolbar>
    </AppBar>
  );
}
