import { Paper, Container, Box, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

export default function BottomNavbar() {
  const year = new Date().getFullYear();

  return (
    <Paper>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px",
        }}
      >
        <Typography>Copyright © {year} Kiran Infotech</Typography>
        <Box>
          <Link>
            <FacebookIcon
              sx={{
                width: 36,
                height: "auto",
                background: "#306AB2",
                color: "white",
                p: 1,
                borderRadius: 50,
              }}
            />
          </Link>
          <Link to="https://www.youtube.com/KiranInfotechIndia">
            <YouTubeIcon
              sx={{
                width: 36,
                height: "auto",
                background: "#306AB2",
                color: "white",
                p: 1,
                borderRadius: 50,
                ml: 1,
              }}
            />
          </Link>
        </Box>
      </Container>
    </Paper>
  );
}
