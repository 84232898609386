import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import TileHeading from "../Components/TileHeading";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { LoaderContext } from "../Context/LoaderContext";
import { Link } from "react-router-dom";

export default function AwsPlans() {
  const Loader = useContext(LoaderContext);
  const [awsPlans, setAwsPlans] = useState([]);

  useEffect(() => {
    Loader.handleShowLoader();
    const fetchAwsPlans = async () => {
      try {
        const response = await axios.get("/AwsPlans/AwsPlans.php");
        setAwsPlans(response.data.data);
        Loader.handleCloseLoader();
      } catch (error) {
        console.error("Error fetching data:", error);
        Loader.handleCloseLoader();
      }
    };
    fetchAwsPlans();
  }, []);

  return (
    <Box sx={{ mb: 3 }}>
      <TileHeading title="AWS Plans" backgroundcolor="#90C4EA" color="white" />
      <Container sx={{ mt: 3 }}>
        <Grid container columnSpacing={4} justifyContent="center">
          {awsPlans.map((item) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }}>
              <motion.div whileHover={{scale:1.1}}>
              <Card sx={{ textAlign: "center", boxShadow: 5 }}>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "white",
                    backgroundColor: "#306AB2",
                    p: 1.5,
                  }}
                >
                  {item.name}
                </Typography>
                <CardContent>
                  <Typography sx={{ fontSize: 16, mb: 3 }} gutterBottom>
                    {item.users === "1" ? "For" : "Upto"} {item.users} Users
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    {item.ram} RAM
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    {item.storage} Storage
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    {item.backupstorage} Backup Storage
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} gutterBottom>
                    {item.duration} usage
                  </Typography>
                </CardContent>
              </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
