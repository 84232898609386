import React, { useContext, useEffect, useState } from "react";
import SimpleAppbar from "../Components/SimpleAppbar";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Container,
  Toolbar,
  CardActions,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import TimeIcon from "@mui/icons-material/AccessTime";
import PreviewIcon from "@mui/icons-material/Preview";
import RegisterIcon from "@mui/icons-material/HowToReg";
import LanguageIcon from "@mui/icons-material/Translate";
import OnlineIcon from "@mui/icons-material/Wifi";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import OfflineIcon from "@mui/icons-material/WifiOff";

import { LoaderContext } from "../Context/LoaderContext";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Events() {
  const Loader = useContext(LoaderContext);
  const [events, setEvents] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    Loader.handleShowLoader()
    axios
      .get("/Events/Events.php?status=completed")
      .then((response) => {
        if (response && response.data && response.data.data) {
          setEvents(response.data.data);
        } else {
          console.error("Error: No data returned from API");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        Loader.handleCloseLoader();
      });
  }, []);

  return (
    <div>
      <Toolbar />
      <SimpleAppbar title="Events Calendar" />

      <Container sx={{ mt: 3 }}>
        {events.map((event) => (
          <Card sx={{ p: 2, borderRadius: 0, mb: 3 }} key={event.id}>
            <Grid container columnSpacing={2}>
              <Grid item md={3}>
                <CardMedia component="img" src={event.imageurl} />
              </Grid>
              <Grid item md={5}>
                <CardContent>
                  <Typography variant="h5">{event.name}</Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {event.agenda}
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item md={4}>
                <CardContent>
                  <Box display="flex" sx={{ p: 1 }}>
                    <CalendarIcon />
                    <Typography sx={{ ml: 1 }}>{event.date}</Typography>
                  </Box>
                  <Box display="flex" sx={{ p: 1 }}>
                    <TimeIcon />
                    <Typography sx={{ ml: 1 }}>
                      {event.starttime} to {event.endtime}
                    </Typography>
                  </Box>
                  <Box display="flex" sx={{ p: 1 }}>
                    <LanguageIcon />
                    <Typography sx={{ ml: 1 }}>{event.language}</Typography>
                  </Box>
                  <Box display="flex" sx={{ p: 1 }}>
                    <ModelTrainingIcon />
                    <Typography sx={{ ml: 1 }}>{event.type}</Typography>
                  </Box>

                  <Box display="flex" sx={{ p: 1 }}>
                    {event.mode === "Online" ? <OnlineIcon /> : <OfflineIcon />}
                    <Typography sx={{ ml: 1 }}>{event.mode}</Typography>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
            <CardActions>
              <Grid container columnSpacing={2}>
                <Grid item md={8} xs={12} sx={{ mb: { xs: 1, sm: 0 } }}>
                  <Button
                    startIcon={<CalendarIcon />}
                    variant="outlined"
                    fullWidth={isSmallScreen}
                  >
                    Add to Google Calendar
                  </Button>
                </Grid>
                <Grid item md={2} xs={12} sx={{ mb: { xs: 1, sm: 0 } }}>
                  <Button
                    startIcon={<PreviewIcon />}
                    variant="outlined"
                    fullWidth={isSmallScreen}
                  >
                    View Details
                  </Button>
                </Grid>
                <Grid item md={2} xs={12} sx={{ mb: { xs: 1, sm: 0 } }}>
                  <Link to={`/Events/Registration/${event.id}`}>
                    <Button
                      startIcon={<RegisterIcon />}
                      variant="contained"
                      fullWidth={isSmallScreen}
                    >
                      Register
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        ))}
      </Container>
    </div>
  );
}
