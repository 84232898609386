import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainRoute from "./MainRoute";
import YoutubeVideoDialog from "./Components/YoutubeVideoDialog";
import { YouTubeVideoDialogState } from "./Context/YoutubeVideoContext";
import { LoaderState } from "./Context/LoaderContext";
import CustomerAwsPlanPrice from "./Pages/CustomerAwsPlanPrice";
import Loader from "./Components/Loader";
import PartnerAwsPlanPrice from "./Pages/PartnerAwsPlanPrice";
import Events from "./Pages/Events";
import EventRegistration from "./Pages/EventRegistration";
import { SnackbarProvider } from "notistack";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import QuickActions from "./Components/QuickActions";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <LoaderState>
          <YouTubeVideoDialogState>
            <Loader />
            <YoutubeVideoDialog />
            <BrowserRouter>
              <Routes>
                <Route path="/" Component={MainRoute} />
                <Route
                  path="/AWSPlansPrice/Customer"
                  Component={CustomerAwsPlanPrice}
                />
                <Route
                  path="/AWSPlansPrice/Partner"
                  Component={PartnerAwsPlanPrice}
                />
                <Route path="/Events" Component={Events} />
                <Route
                  path="/Events/Registration/:id"
                  Component={EventRegistration}
                />
              </Routes>
            </BrowserRouter>
            <QuickActions />
          </YouTubeVideoDialogState>
        </LoaderState>
      </SnackbarProvider>
    </div>
  );
}

export default App;
